import React, { useState, useEffect, useRef } from 'react'
import classes from './MoneyTransferBox.module.scss'
import Typography, { Variant as TypographyVariant } from '../typography/Typography'
import NumberFormat from 'react-number-format';
import Button from '../button/Button'
import ArrowDown from '../../icons/ArrowDown'
import DropDownList from '../dropDown/DropDownList'
import theme from '../../theme/Theme'
import Flag from '../flag/Flag'
import DropDownItem from '../dropDown/DropDownItem'
import Box from '../box/Box'
import { useMediaQuery } from '@mui/material';


const CurrencyInputField: React.FC<{id?: string;
  loading?: boolean;
  decimalScale?: number;
  onBlur?: () => void;
  eraseOnFocus?: boolean;
  value: string | number;
  editable?: boolean;
  autoFocus?: boolean;
  handleChange: Function;
  label: string;
  flagCode?: string;
  currency: string;
  errorMessage?: string;
  countrySelectable?: boolean;
  countriesList?: any[];
  handleCountryChange?: Function;
  listStyle?: object | any;
  onSearch?: Function;
  setOpenRequestDialog?: Function;
  receiveSuggestionsLabel?: string;
  showPopularCurrencies?: boolean;
  showCountryName?: boolean;
  minValue?: number;}> = ({ id, label, flagCode, currency, errorMessage, loading, decimalScale = 2, handleChange, onBlur, eraseOnFocus = false, value, editable = true, autoFocus, countrySelectable, countriesList, handleCountryChange, listStyle, onSearch, receiveSuggestionsLabel, setOpenRequestDialog, showPopularCurrencies, showCountryName, minValue }) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [dropListOpen, setDropListOpen] = useState(false);
  const exchangeInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [inputValue, setInputValue] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

  useEffect(() => {
    if (autoFocus && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);


  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target) &&
      exchangeInputRef?.current && !exchangeInputRef?.current?.contains(event?.target)) {
      setDropListOpen(false);
    }
  };

  useEffect(() => {
    document?.addEventListener('mousedown', handleClickOutside);
    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setInputValue(value ? value.toString() : '')
  }, [value])

  useEffect(() => {
    let inputChangeTimer;
    clearTimeout(inputChangeTimer);

    inputChangeTimer = setTimeout(() => {
      if (isTyping) handleChange(inputValue)
    }, 800);

    return () => {
      clearTimeout(inputChangeTimer);
    };
  }, [inputValue])

  const handleFocus = () => {
    setIsFocused(true);
    if (eraseOnFocus && value === '0.00') {
      handleChange('');
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsTyping(false)
    if (onBlur) onBlur();
  };

  const handleInputChange = (values) => {
    const {  floatValue } = values;
    if (editable) {
      setInputValue(floatValue ? floatValue.toFixed(decimalScale) : '');
    }
  };

  const handleButtonClick = () => {
    if (countrySelectable) setDropListOpen(!dropListOpen)
  }

  const handleDropDown = (country) => {
    setDropListOpen(false)
    if (handleCountryChange) handleCountryChange(country)
  }

  const renderCountries = (country: any, i: number) => {
    if (!country) return;

    return <DropDownItem
      key={i}
      value={country?.id || country?.country_id}
      data={country}
      onClick={() => handleDropDown(country)}
      listStyle={listStyle}
      id={country?.currency_code}
    >
      <Box display='flex' alignItems='center'>
        <Flag code={country?.iso2} />
        {!showCountryName && <Box ml={2}>{`${country?.currency_code} - ${country?.currency_name}`}</Box>}
        {showCountryName && <Box ml={2} display='flex'>
          <Box flexGrow={1}>{`${country?.country_to}`}</Box>
          <>({`${country?.currency_code}`})</>
        </Box>}
      </Box>
  </DropDownItem>
  };

  // const calculateDropdownPosition = () => {
  //   if (exchangeInputRef?.current) {
  //     const containerRect = exchangeInputRef.current.getBoundingClientRect();
  //     return {
  //       top: containerRect.bottom,
  //       left: containerRect.left ,
  //       width : containerRect.width
  //     };
  //   }
  //   return { top: 0, left: 0 };
  // };

  const dropdownPosition = {
    position: 'absolute',
    top: exchangeInputRef?.current?.offsetTop + exchangeInputRef?.current?.offsetHeight,
    width: exchangeInputRef?.current?.offsetWidth,
    left: exchangeInputRef?.current?.offsetLeft,
  }

  const countrySelectButtonStyle = { padding: '16px', borderRadius: '0 8px 8px 0', height: '100%', minWidth: isNotMobile ? '150px' : '130px', width: `40%`, flex: 0.3 }

  return (
    <>
      <div ref={exchangeInputRef} className={classes[`rb-currencyBox-container`]}>
        <div className={`
          ${classes[`rb-input-container`]}
          ${!countrySelectable ? classes[`rb-input-non-selectable`] : ''}
        `}>
          <div className={`
            ${classes[`rb-inputAmount-container`]}
            ${(errorMessage && !isFocused) ? classes[`rb-inputAmount-container-error`] : ''}
            ${isFocused ? classes[`rb-inputAmount-container-active`] : ''}
            `}>
            <Typography id="sendMsg" variant={TypographyVariant.body3} color={isFocused ? "#1160F7" : "#626680"}>{label}</Typography>
            <Typography id="loading" variant={TypographyVariant.label1} style={{ fontSize: '20px', height: '100%', display: loading ? 'flex' : 'none', alignItems: 'center' }} color='#626680'><span>Loading...</span></Typography>
            <NumberFormat
              value={inputValue || ''}
              displayType="input"
              aria-label="Amount"
              id={id}
              style={{ display: !loading ? 'inline-block' : 'none', width: '100%', padding: 0 }}
              placeholder="0.00"
              readOnly={!editable}
              onFocus={handleFocus}
              onValueChange={handleInputChange}
              onChange={(e) => setIsTyping(e ? true : false)}
              onBlur={handleBlur}
              thousandSeparator={true}
              decimalScale={2}
              isNumericString={true}
              getInputRef={inputRef}
              isAllowed={(values) => {
                const { floatValue } = values;
                return isNaN(minValue) || !floatValue ? true: floatValue >= minValue;
              }}
            />
          </div>
          <Button id={`${id}-dropDownCountry`} onClick={handleButtonClick} style={countrySelectButtonStyle} >
            <div className={classes[`rb-country-selector`]}>
              <div>
                <Flag code={flagCode} width={isNotMobile ? 31.5 : 26.5} height={isNotMobile ? 22.5 : 18.5} />
                <Typography id="sendCurrency" variant={isNotMobile ? 'h4' : 'h5'} color="#FFF" weight='medium'>{currency}</Typography>
              </div>
              {countrySelectable && <ArrowDown width={24} height={24} color={'#FFFFFF'} />}
            </div>
          </Button>
        </div>
        {errorMessage && <div>
          <Typography id="errorMessage" variant={'body3'} color={theme.palette.error.dark}>{errorMessage}</Typography>
        </div>}
      </div>
      {countrySelectable && countriesList ?
        <DropDownList
          innerRef={dropdownRef}
          dropListOpen={dropListOpen}
          style={dropdownPosition}
          setDropListOpen={setDropListOpen}
          onSearch={onSearch}
          setOpenRequestDialog={setOpenRequestDialog}
          receiveSuggestionsLabel={receiveSuggestionsLabel}
          primaryListItems={
            countriesList?.length 
              ? showPopularCurrencies
                ? countriesList?.filter((c) => c.favorite)?.map((country, i) => renderCountries(country, i))
                : countriesList?.map((country, i) => renderCountries(country, i))
              : countriesList
          }
          primaryListLabel={showPopularCurrencies && countriesList?.length ? "Popular currencies" : null}
          secondaryListLabel={showPopularCurrencies && countriesList?.length ? 'All currencies' : null}
          secondaryListItems={showPopularCurrencies && countriesList?.length ? countriesList?.filter(c => !c.favorite)?.map((country, i) => renderCountries(country, i)) : null}
        />
        : null
      }
    </>
  )
}

export default CurrencyInputField