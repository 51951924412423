import { useState, useEffect } from 'react';
var useCustomMediaQuery = function useCustomMediaQuery(query) {
  var isSSR = typeof window === 'undefined';
  var _useState = useState(!isSSR),
    matches = _useState[0],
    setMatches = _useState[1];
  useEffect(function () {
    if (!isSSR) {
      var mediaQueryList = window.matchMedia(query);
      setMatches(mediaQueryList.matches);
      var handleChange = function handleChange(event) {
        setMatches(event.matches);
      };
      mediaQueryList.addListener(handleChange);
      return function () {
        mediaQueryList.removeListener(handleChange);
      };
    }
  }, [query, isSSR]);
  return matches;
};
export default useCustomMediaQuery;